import * as PIXI from 'pixi.js';

const SmudgeLogo = () => {
  const resetBtn = document.querySelector('.js-logo-reset');
  const exportBtn = document.querySelector('.js-logo-export');
  const controls = document.querySelector('.js-canvas-controls');

  const app = new PIXI.Application({
    width: window.innerWidth,
    height: window.innerHeight,
    resizeTo: window,
    autoStart: false,
    antialiasing: true,
    resolution: Math.min(2, window.devicePixelRatio),
    backgroundColor: 0xBB2649,
    view: document.querySelector('.js-canvas-logo'),
    preserveDrawingBuffer: true
  });

  const rt = [];
  for (let i = 0; i < 2; i++) {
    rt.push(PIXI.RenderTexture.create({ width: app.screen.width, height: app.screen.height }));
  }
  let current = 0;

  let tempBg, bg, brush, displacementFilter;
  let size = 50;
  let intensity = 3;

  const container = new PIXI.Container();
  app.stage.addChild(container);

  PIXI.Assets.add("finger", new URL("../img/finger_blur.png", import.meta.url).href);
  PIXI.Assets.add("logo", new URL("../img/Logo_Hydra.png", import.meta.url).href);
  const texturesPromise = PIXI.Assets.load(["logo", "finger"]);
  let textures;
  console.log(tempBg)
  texturesPromise.then((_textures) => {
    textures = _textures;
    tempBg = new PIXI.Sprite(textures.logo);
    const w = window.innerWidth * 0.6;
    const h = w * (510 / 806);
    tempBg.width = w;
    tempBg.height = h;
    tempBg.x = (app.screen.width - w) / 2;
    tempBg.y = (app.screen.height - h) / 2;
    app.renderer.render(tempBg, { renderTexture: rt[0] });
    bg = new PIXI.Sprite(rt[0]);
    brush = new PIXI.Sprite(_textures.finger);
    brush.anchor.set(0.5);
    brush.width = size;
    brush.height = size;
    displacementFilter = new PIXI.filters.DisplacementFilter(brush);
    container.filters = [displacementFilter];

    container.addChild(bg, brush);
    app.stage.interactive = true;

    app.stage.hitArea = app.screen;
    app.stage
      .on("pointerdown", onPointerDown)
      .on("pointerup", onPointerUp)
      .on("pointermove", onPointerMove);

    app.start();
  });

  function smudge(event) {
    app.renderer.render(app.stage, { renderTexture: rt[1 - current] });
    bg.texture = rt[1 - current];
    current = 1 - current;
  }

  let dragging = false;

  function onPointerDown(event) {
    controls.classList.add('is-visible');
    dragging = true;
    onPointerMove(event);
  }

  function onPointerMove(event) {
    const x = event.data.global.x;
    const y = event.data.global.y;
    if (dragging) {
      displacementFilter.scale.x = Math.atan(x - brush.x) * intensity;
      displacementFilter.scale.y = Math.atan(y - brush.y) * intensity;
      brush.position.copyFrom(event.data.global);
      smudge(event);
    } else {
      displacementFilter.scale.x = 10;
      displacementFilter.scale.y = 10;
      brush.position.copyFrom(event.data.global);
    }
  }

  function onPointerUp() {
    dragging = false;
  }

  resetBtn.addEventListener('click', () => {
    app.renderer.render(tempBg, { renderTexture: rt[0] });
    app.renderer.render(tempBg, { renderTexture: rt[1] });
  });
  exportBtn.addEventListener('click', () => {
    const image = app.renderer.view.toDataURL('image/png');
    const aDownloadLink = document.createElement('a');
    aDownloadLink.download = 'hydra_header.png';
    aDownloadLink.href = image;
    aDownloadLink.click();
  });

  function resizeScene () {
    const w = window.innerWidth * 0.6;
    const h = w * (510 / 806);
    tempBg.width = w;
    tempBg.height = h;
    tempBg.x = (app.screen.width - w) / 2;
    tempBg.y = (app.screen.height - h) / 2;
    app.renderer.render(tempBg, { renderTexture: rt[0] });
    app.renderer.render(tempBg, { renderTexture: rt[1] });
  }

  window.addEventListener('resize', () => {
    resizeScene();
    setTimeout(() => {
      resizeScene();
    }, 1000);
  });
};

export default SmudgeLogo;
