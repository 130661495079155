const elRefuse = document.querySelector('.js-cookie-refuse');
const elAccept = document.querySelector('.js-cookie-accept');
const elCookieBanner = document.querySelector('.js-cookie-banner');

function loadAnalytics () {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-2HRX5XFXEE');
}

elRefuse.addEventListener('click', () => {
    localStorage.setItem('accept-cookies', false);
    elCookieBanner.classList.remove('is-visible');
});
elAccept.addEventListener('click', () => {
    localStorage.setItem('accept-cookies', true);
    elCookieBanner.classList.remove('is-visible');
    loadAnalytics();
});

// Check if the cookie banner should be visible
if (!localStorage.getItem('accept-cookies')) {
    if (localStorage.getItem('accept-cookies') === 'true') {
        loadAnalytics();
    }
    window.setTimeout(() => {
        elCookieBanner.classList.add('is-visible');
    }, 1000);
}
