import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

gsap.from('.js-logo path', {
    opacity: 0,
    duration: 1.5,
    stagger: 0.4,
    ease: 'power3.out',
    scrollTrigger: '.c-title',
    delay: 0.3
});

let fades = gsap.utils.toArray('.js-fade');
fades.forEach((element, i) => {
    gsap.from(element, {
        opacity: 0,
        duration: 2,
        ease: 'power3.out',
        scrollTrigger: {
            trigger: element,
            start: 'top 75%'
        }
    });
});
