// Create the DOM span for the mouse
const mouse = document.createElement('span');
mouse.classList.add('o-mouse');
document.body.appendChild(mouse);

let invert = false;

// Listen to mouse move events and update mouse position
window.addEventListener('mousemove', (e) => {
    mouse.classList.add('is-visible');
    mouse.style.top = `${e.clientY}px`;
    mouse.style.left = `${e.clientX}px`;
});
// Hide the mouse when user is leaving the window
document.body.addEventListener('mouseleave', (e) => {
    mouse.classList.remove('is-visible');
});

/* When hovering the purple section */
/* Switch the mouse colour */
const agenciesSection = document.querySelector('.js-agencies');
agenciesSection.addEventListener('mouseenter', () => {
    mouse.classList.add('is-invert');
});
agenciesSection.addEventListener('mouseleave', () => {
    mouse.classList.remove('is-invert');
});

/* When hovering a mailto link */
const linksMail = document.querySelectorAll('a[href^="mailto:"]');
linksMail.forEach((link) => {
    link.addEventListener('mouseenter', () => {
        mouse.classList.add('is-email');
    });
    link.addEventListener('mouseleave', () => {
        mouse.classList.remove('is-email');
    });
});


/* When hovering a classic link */
const links = document.querySelectorAll('a:not([href^="mailto:"])');
links.forEach((link) => {
    link.addEventListener('mouseenter', () => {
        mouse.classList.add('is-link');
    });
    link.addEventListener('mouseleave', () => {
        mouse.classList.remove('is-link');
    });
});
